import { useEffect, useRef } from "react";
import FillButton from "../components/FillButton";
import GridBackground from "../components/GridBackground";
import { useTheme } from "../contexts/ThemeContext";
import "../styles/hero-section.css";
import useResponsiveView from "../hooks/useResponsiveView";

const HeroSection = () => {
    const {theme} = useTheme();
    const textContainerRef = useRef(null);
    const isMobile = useResponsiveView();
    
    const handleMouseMove = (event)=>{
        document.documentElement.style.setProperty("--x", `${event.clientX}px`);
        document.documentElement.style.setProperty("--glow-x", `${event.clientX - textContainerRef.current?.offsetLeft - 316 + window.scrollX}px`);
        document.documentElement.style.setProperty("--y", `${event.clientY}px`);
        document.documentElement.style.setProperty("--glow-y", `${event.clientY - textContainerRef.current?.offsetTop - 215 + window.scrollY}px`);
    }

    return (
        <section className="hero-section" id="hero-section" onMouseMove={handleMouseMove}>
            <GridBackground />
            <div className={`trusted-chip ${theme}`}>
                <div className="trusted-chip-icons">
                    <img src="image3.jpeg" alt="" />
                    <img style={{position:"relative", left:"-15%"}} src="image2.jpeg" alt="" />
                    <img style={{position:"relative", left:"-30%"}} src="image1.png" alt="" />
                </div>
                <div>Trusted By <b>100+</b> People</div>
            </div>
            <div className="hero-title-container">
                <h1 className="hero-title-text before">Effortless Hiring,<br /> Exceptional Teams</h1>
                <h1 className="hero-title-text"
                    ref={textContainerRef}
                    style={{
                        backgroundClip: "text",
                        backgroundImage : "url(glow-bg.svg)",
                        backgroundSize: "644px 440px",
                        backgroundRepeat: "no-repeat"
                    }}
                >Effortless Hiring,<br /> Exceptional Teams</h1>
                <div className="hero-title-effect" />
            </div>
            <div className="hero-desc">Enhance Your Hiring Process with Smart AI and Machine Learning. Rapidly review resumes, discover top candidates more efficiently, and make informed hiring decisions to stay ahead in a competitive recruitment space.</div>

            <FillButton className={`hero-get-started-button ${theme}`} inverse={true} value={"Get started"}
                onClick ={()=>{
                    document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                }}
            />

            <div className="hero-glow left" />
            <div className="hero-glow right" />
        </section>
    )
}

export default HeroSection;