import { createContext, useContext, useEffect, useState } from "react";

const CurrencyContext = createContext();
export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({children}) => {
    const [country, setCountry] = useState(null);
    const [currency, setCurrency] = useState('USD');

    const changeCurrency = (currency)=>{
        localStorage.setItem("currency", currency);
        setCurrency(currency);
    }

    useEffect(()=>{
        const curr = localStorage.getItem("currency");
        const country = localStorage.getItem("country");
        if(curr && country){
            setCurrency(curr);
            setCountry(country);
            return;
        }

        fetch("https://api.ipify.org/?format=json")
		.then(response=>{
			if(response.status === 200){
				response.json()
				.then(data=>{
					fetch("/get-pricing", {
						method:"post",
						headers:{
							'Content-Type' : "application/json"
						},
						body : JSON.stringify({
							ip : data.ip
						})
					})
					.then(response=>{
						if(response.status === 200){
							response.json()
							.then(data=>{
								console.log(data);
                                setCurrency(data.currency);
                                setCountry(data.country);
                                localStorage.setItem('currency', data.currency);
                                localStorage.setItem('country', data.country);
							})
						}
						else {
							console.log(response);
						}
					})
				})
			}
			else {
				console.log(response);
			}
		})
		.catch((e)=>console.log(e));
    }, [])

    return (
        <CurrencyContext.Provider value={{currency, country, changeCurrency}}>
            {children}
        </CurrencyContext.Provider>
    )
}