import { useEffect, useRef } from "react";
import "../styles/tnc.css";

const TermsAndConditions = ()=>{
    const sectionRef = useRef();

    useEffect(()=>{
        if(!sectionRef) return;
        sectionRef.current.scrollIntoView({behavior:"smooth"});
    }, [])

    return (
    <section ref={sectionRef} className="tnc-section">
        <h1>Terms and Conditions</h1>
        <p><strong>Effective Date:</strong> 28 Aug 2024</p>

        <p>Welcome to Scan2Hire! These Terms and Conditions ("T&C") govern your use of our software services. By accessing or utilizing the Services, you agree to be bound by these Terms, which form a legal agreement between you and our organization. Please read these Terms thoroughly before using our Services. If you do not agree with any part of these Terms, discontinue your use of the Services immediately.</p>

        <h2>1. Definitions</h2>
        <p>In these Terms, the following definitions apply:</p>
        <ul>
            <li><strong>"Scan2Hire", "we", "our", or "us":</strong> Refers to Scan2Hire, the provider of the Services.</li>
            <li><strong>"User", "you", or "your":</strong> Refers to any individual or entity accessing or using our Services.</li>
            <li><strong>"Account":</strong> Refers to the user account you create to access our Services.</li>
            <li><strong>"Content":</strong> Refers to all text, images, data, software, and other materials provided or generated through our Services.</li>
        </ul>

        <h2>2. Account Registration and Security</h2>
        <h3>2.1 Account Creation</h3>
        <p>To access certain features of the Services, you must create an account. You agree to provide accurate, current, and complete information when creating your account and to update this information as necessary.</p>

        <h3>2.2 Account Security</h3>
        <p>You are responsible for maintaining the confidentiality of your account credentials. Notify us immediately of any unauthorized access to or use of your account. Scan2Hire is not liable for any loss or damage arising from your failure to maintain the security of your account.</p>

        <h2>3. Use of Services</h2>
        <h3>3.1 License Grant</h3>
        <p>Subject to your compliance with these Terms, Scan2Hire grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal or internal business purposes.</p>

        <h3>3.2 Prohibited Uses</h3>
        <p>You agree not to:</p>
        <ul>
            <li>Use the Services for any illegal or unauthorized purpose.</li>
            <li>Reproduce, modify, distribute, display, or otherwise exploit the Services or any Content without our prior written consent.</li>
            <li>Interfere with or disrupt the operation of the Services.</li>
            <li>Use automated systems (e.g., bots, scrapers) to access the Services without our prior written permission.</li>
            <li>Attempt to gain unauthorized access to any portion of the Services, including other users' accounts.</li>
        </ul>

        <h3>3.3 User Content</h3>
        <p>You are responsible for any content you upload, post, or otherwise make available through the Services. By providing User Content, you grant Scan2Hire a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, display, and distribute your content in connection with operating the Services.</p>

        <h3>3.4 Monitoring and Enforcement</h3>
        <p>Scan2Hire reserves the right, but is not obligated, to monitor user activity and content to ensure compliance with these Terms. We may remove or disable access to any content that violates these Terms without prior notice.</p>

        <h2>4. Intellectual Property</h2>
        <h3>4.1 Ownership</h3>
        <p>The Services and all related content, features, and functionality, including but not limited to software, code, text, graphics, logos, and trademarks, are the exclusive property of Scan2Hire and its licensors.</p>

        <h3>4.2 Limited Use License</h3>
        <p>Scan2Hire grants you a limited license to use the Services and content for your personal or internal business use, subject to these Terms. This license does not grant you any ownership rights in the Services or content.</p>

        <h3>4.3 Feedback</h3>
        <p>If you provide feedback, suggestions, or ideas regarding the Services, you agree that Scan2Hire may use such feedback for any purpose without compensation or obligation to you.</p>

        <h2>5. Payment and Subscription Terms</h2>
        <h3>5.1 Payment Obligations</h3>
        <p>Certain features of the Services may be subject to fees or subscription charges. You agree to pay all applicable fees for your use of the Services, including any recurring subscription fees. All payments are due in advance and are non-refundable, except as expressly provided in these Terms.</p>

        <h3>5.2 Billing and Renewal</h3>
        <p>If you subscribe to a paid service, you authorize Scan2Hire to charge your payment method on a recurring basis. Your subscription will automatically renew at the end of each term unless you cancel it before the renewal date.</p>

        <h3>5.3 Cancellation and Termination</h3>
        <p>You may cancel your subscription at any time through your account settings. Upon cancellation, your access to the paid features will continue until the end of the current subscription term.</p>

        <h2>6. Data Protection and Privacy</h2>
        <h3>6.1 Privacy Policy</h3>
        <p>Your use of the Services is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.</p>

        <h3>6.2 Data Security</h3>
        <p>We implement reasonable measures to protect your data from unauthorized access, use, or disclosure. However, no system can be completely secure, and we cannot guarantee the absolute security of your data.</p>

        <h3>6.3 Data Retention</h3>
        <p>We retain your data only as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, and enforce our agreements.</p>

        <h2>7. Third-Party Services and Content</h2>
        <h3>7.1 Third-Party Links</h3>
        <p>The Services may contain links to third-party websites, services, or content. We do not endorse or assume any responsibility for the content or practices of third-party sites. You access third-party content at your own risk.</p>

        <h3>7.2 Third-Party Integrations</h3>
        <p>The Services may integrate with third-party services or software. Your use of such integrations is subject to the third-party provider's terms and conditions.</p>

        <h2>8. Disclaimers and Limitation of Liability</h2>
        <h3>8.1 Disclaimers</h3>
        <p>The Services are provided "as is" and "as available", without any warranties of any kind, either express or implied. Scan2Hire disclaims all warranties to the fullest extent permitted by law.</p>

        <h3>8.2 Limitation of Liability</h3>
        <p>Scan2Hire and its affiliates, licensors, and service providers shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use or inability to use the Services. In no event shall Scan2Hire's total liability exceed the amount paid by you.</p>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless Scan2Hire and its affiliates from any claims, liabilities, damages, losses, or expenses arising out of your use of the Services, your User Content, or your violation of any applicable laws or regulations.</p>

        <h2>10. Law</h2>
        <h3>10.1 Governing Law</h3>
        <p>These Terms and your use of the Services shall be governed by and construed in accordance with the laws, without regard to its conflict of law principles.</p>

        <h3>10.2 Class Action Waiver</h3>
        <p>You agree that any disputes will be resolved on an individual basis, and you waive your right to participate in any class action.</p>

        <h3>10.3 Injunctive Relief</h3>
        <p>Scan2Hire reserves the right to seek injunctive or other equitable relief in any court of competent jurisdiction to prevent or address any breach of these Terms.</p>

        <h2>11. Modifications to the Services and Terms</h2>
        <h3>11.1 Changes to the Services</h3>
        <p>Scan2Hire reserves the right to modify, suspend, or discontinue any aspect of the Services at any time, with or without notice.</p>

        <h3>11.2 Changes to the Terms</h3>
        <p>We may update these Terms from time to time at our sole discretion. Your continued use of the Services after any changes constitutes your acceptance of the new Terms.</p>

        <h2>12. Termination</h2>
        <h3>12.1 Termination by You</h3>
        <p>You may terminate your account and cease using the Services at any time. Upon termination, you must cease all use of the Services and delete any copies of materials obtained from the Services.</p>

        <h3>12.2 Termination by Us</h3>
        <p>We reserve the right to terminate your access to the Services at any time, with or without cause, and with or without notice, including for any violation of these Terms.</p>

        <h3>12.3 Effect of Termination</h3>
        <p>Upon termination of your account, you will no longer have access to any data associated with your account. We may retain certain information as required by law or for legitimate business purposes.</p>

        <h2>13. Miscellaneous</h2>
        <h3>13.1 Entire Agreement</h3>
        <p>These Terms, together with our Privacy Policy and any other agreements or policies referenced herein, constitute the entire agreement between you and Scan2Hire regarding your use of the Services.</p>

        <h3>13.2 Severability</h3>
        <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

        <h3>13.3 Contact Information</h3>
        <p>If you have any questions about these Terms or the Services, please contact us at:</p>
        <ul>
            <li>Call: <a href="tel:+91-9265046003">+91-9265046003</a></li>
            <li>Email: <a href="mailto:connect@scan2hire.com">connect@scan2hire.com</a></li>
        </ul>

        <h2>14. Additional Provisions</h2>
        <h3>14.1 Electronic Communications</h3>
        <p>By using the Services, you consent to receiving electronic communications from Scan2Hire. You agree that any notices, agreements, or other communications that we send to you electronically will satisfy any legal communication requirements.</p>

        <h3>14.2 Language</h3>
        <p>These Terms are written in English. If there is a conflict between the English version and a translated version, the English version shall prevail.</p>

        <h2>15. Acknowledgment</h2>
        <p>By using the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and any other agreements or policies referenced herein.</p>
    </section>
    )
}

export default TermsAndConditions;