import { useEffect, useRef, useState } from "react";
import "../styles/custom-inputs.css";
import { Collapse, Fade, Grow, Slide, Zoom } from "@mui/material";
import CheckIcon from "../icons/CheckIcon";
import DownArrowIcon from "../icons/DownArrowIcon";
import UpArrowIcon from "../icons/UpArrowIcon";
import { useTheme } from "../contexts/ThemeContext";
import PhoneInput from "react-phone-number-input";
import "./CustomInputs.css";

const CustomSelect = ({ className, selectedOption, onChange, name, id, options, placeholder="Select" }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const handleOptionClick = (option) => {
        onChange(option);
        setIsOpen(false);
    };

    const { theme} = useTheme();

    return (
        <div ref={ref} className={`custom-select ${className} ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
            <div className={`custom-select__trigger ${selectedOption ? '' : 'placeholder'}`}>
                {selectedOption || placeholder}
                {isOpen ? <UpArrowIcon color={theme === "dark" ? "white" : "black"} /> : <DownArrowIcon color={theme === "dark" ? "white" : "black"} />}
            </div>
            <Fade in={isOpen} style={{origin:"top"}} timeout={300}>
                <div>
                    <div className="custom-select__bar"></div>
                    <div className="custom-select__options">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`custom-select__option ${selectedOption === option ? 'selected' : ''}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </div>
            </Fade>
        </div>
    );
};

const CustomCheckbox = ({className, value, onChange=()=>{}, name, id, ...params})=>{
    const [checked, setChecked] = useState(value);

    return (
        <div 
            className={`custom-checkbox ${className} ${checked ? "checked" : null}`} 
            {...params} 
            aria-label="checkbox"
            onClick={()=>{
                setChecked(prev=>!prev)
                onChange({target : {checked : !checked}})
            }}    
        >
            <input type="checkbox" checked={checked} onChange={(e)=>setChecked(e.target.checked)} style={{display:"none"}} onClick={()=>setChecked(prev=>!prev)} name={name} id={id} />
            <Zoom in={checked}>
                <div>
                    <CheckIcon />
                </div>
            </Zoom>
        </div>
    )
}

const CustomPhoneNumber = ({className, value, onChange=()=>{}, label, id, ...params}) => {
    return (
        <div
            style={{
                width:"100%",
                height:"100%"
            }}
        >
            <label style={{margin:"0.5rem", fontSize:"0.9rem", cursor:"pointer"}} htmlFor={id}>{label}</label>
            <PhoneInput 
                className={`custom-phone-number ${className}`}
                value={value}
                onChange={onChange}
                numberInputProps={{id:id}}
                {...params}
            />
        </div>
    )
}

export { CustomSelect, CustomPhoneNumber, CustomCheckbox };