import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from './contexts/ThemeContext';
import { CurrencyProvider } from './contexts/CurrencyContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CurrencyProvider>
        <App />
        <ToastContainer toastStyle={{backgroundColor:"var(--background-color)", color:"var(--text-color)"}}/>
      </CurrencyProvider>
    </ThemeProvider>
  </React.StrictMode>
);
