import { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import { useTheme } from "../contexts/ThemeContext";
import "../styles/pricing-section.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useResponsiveView from "../hooks/useResponsiveView";
import { useCurrency } from "../contexts/CurrencyContext";
import { Switch } from "@mui/material";
import { MaterialUISwitch } from "../components/CustomMUIInputs";
import eventBus from "../eventBus";
import CheckIcon from "../icons/CheckIcon";

const Plans = [
    {
        name : "Free",
        pricing : {
            'USD' : {
                monthly : 0,
                yearly : 0
            },
            'INR' : {
                monthly : 0,
                yearly : 0
            }
        },
        features : [
            "Up to 10 Open Jobs",
            "Up to 100 Active Resumes",
            "Up to 3 Users"
        ],
        cta : "Get Started"
    },
    {
        name : "Essential",
        pricing : {
            'USD' : {
                monthly : 40,
                yearly : 30
            },
            'INR' : {
                monthly : 3300,
                yearly : 2415
            }
        },
        features : [
            "Up to 25 open jobs",
            "Up to 500 active resumes",
            "Up to 5 users",
        ],
        cta : "Get Started"
    },
    {
        name : "Premium",
        pricing : {
            'USD' : {
                monthly : 110,
                yearly : 100
            },
            'INR' : {
                monthly : 9200,
                yearly : 8350
            }
        },
        features : [
                "Up to 100 open jobs",
                "Up to 2k active resumes",
                "Up to 10 users",
        ],
        cta : "Get Started"
    },
    // {
    //     name : "Enterprise",
    //     type : "custom",
    //     pricing : {
    //         'USD' : {
    //             monthly : "Talk to Sales",
    //             yearly : "Talk to Sales"
    //         },
    //         'INR' : {
    //             monthly : "Talk to Sales",
    //             yearly : "Talk to Sales"
    //         }
    //     },
    //     features : [
    //         "Fully customizable",
    //         "+ Additional features",
    //         "+ Flexible & Affordable"
    //     ],
    //     cta : "Let's Connect"
    // }
]

const PricingSection = ()=>{
    const { theme } = useTheme();
    const [duration, setDuration] = useState('m');
    const isMobile = useResponsiveView();
    const { currency, changeCurrency } = useCurrency();

    return (
        <section className="pricing-section" id="pricing-section">
            <SectionTitle title={"Get Ongoing Access with Easy Payments!"} />
            <div className="pricing-box">
                <div className="pricing-box-header">
                    <div className="pricing-toggle"></div>
                    <div className={`pricing-chip ${theme}`}>
                        <div className={`pricing-chip-option ${duration === 'm' ? 'active' : null}`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setDuration('m');
                            }}
                        >Monthly</div>
                        <div className={`pricing-chip-option ${duration === 'y' ? 'active' : null}`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setDuration('y');
                            }}
                        >Yearly 
                            <div className={`pricing-chip-suboption ${theme}`}>
                                Save up to 30%
                            </div>
                        </div>
                    </div>

                    <div className="pricing-toggle">
                        <MaterialUISwitch onthumb={"usd.svg"} offthumb={"inr.svg"} trackbg={theme === 'dark' ? 'rgba(217, 217, 217, 0.2)' : 'rgba(105, 105, 105, 0.2)'} 
                            checked={currency === "USD"}
                            onClick={(e)=>e.stopPropagation()}
                            onChange={(event, checked)=>changeCurrency(checked ? 'USD' : 'INR')}
                        />
                    </div>
                </div>

                <div className="pricing-plans">
                    {
                        Plans.map(plan=>{
                            return (
                                <div className={`pricing-plans-card ${theme}`}>
                                    <h3 className="pricing-card-name">{plan.name}</h3>
                                    <div className="pricing-card-price" >
                                        <div>
                                            {currency === 'INR' ? '₹' : '$'}
                                            {(plan.pricing[currency][duration === 'm' ? 'monthly' : 'yearly'])?.toLocaleString()}
                                            <span>{plan.name === "Free" ? "/7 days" : "/month"}</span>
                                        </div>
                                    </div>
                                    <div className="pricing-card-features-container">
                                        {
                                            plan.features?.map((feature, idx)=>(
                                                <div key={idx+plan.name} className="pricing-card-feature">{feature}</div>
                                            ))
                                        }
                                    </div>
                                    <div className="pricing-button" onClick={()=>{
                                        eventBus.dispatchEvent(new CustomEvent('planSelected', {detail : plan.name}));
                                        document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                                    }}>{plan.cta}</div>
                                </div>
                            )
                        })
                    }

                    <div className={`pricing-plans-card custom ${theme}`}>
                        <h3 className="pricing-card-name">{"Enterprise"}</h3>
                        <div className="pricing-card-price" >
                            Talk to us
                            <span className="custom"> For Best Suitable plan </span>
                        </div>
                        <div className="pricing-card-features-container">
                            <div className="pricing-card-feature">Personalized to Your needs</div>
                            <div className="pricing-card-feature">On-Demand Custom Features</div>
                            <div className="pricing-card-feature">Scalable to Fit Large Teams</div>
                        </div>
                        <div className="pricing-button" onClick={()=>{
                            eventBus.dispatchEvent(new CustomEvent('planSelected', {detail : "Enterprise"}));
                            document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                        }}>{"Let's connect"}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection;