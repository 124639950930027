import "../styles/hiw-section.css";
import { useEffect, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import AccelerateScreeningIcon from "../icons/AccelerateScreeningIcon";
import EnhanceAccuracyIcon from "../icons/EnhanceAccuracyIcon";
import ImproveCandidateMatchingIcon from "../icons/ImproveCandidateMatchingIcon";
import SaveTimeAndResourcesIcon from "../icons/SaveTimeAndResourcesIcon";
import SeamlessIntegrationIcon from "../icons/SeamlessIntegrationIcon";
import SectionTitle from "../components/SectionTitle";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";
import useResponsiveView from "../hooks/useResponsiveView";

const HIWContent = [
    {
        menuName : "Accelerate Screening",
        menuIcon : AccelerateScreeningIcon,
        content : [
            {
                itemName : "Accelerate Screening",
                itemDescription : "Automate resume screening to quickly identify the most qualified candidates, speeding up your hiring process."
            },
            {
                itemName : "Faster Processing",
                itemDescription : "AI & ML algorithms process resumes in seconds, cutting manual review time by up to 70%."
            },
            {
                itemName : "Immediate Insights",
                itemDescription : "Access key candidate information instantly for quicker decision-making."
            },
            {
                itemName : "Focus on Top Talent",
                itemDescription : "Efficiently identify the strongest candidates and concentrate on the best match."
            },
        ]
    },
    {   
        menuName : "Enhance Accuracy",
        menuIcon : EnhanceAccuracyIcon,
        content : [
            {
                itemName : "Enhance Accuracy",
                itemDescription : "Reduce errors with AI & ML precision, ensuring accurate data extraction and consistent results."
            },
            {
                itemName : "Precision Parsing",
                itemDescription : "Achieve over 95% accuracy in data extraction, minimizing the risk of human error."
            },
            {
                itemName : "Consistent Results",
                itemDescription : "Standardize resume data to ensure uniformity across all applications."
            },
            {
                itemName : "Data Integrity",
                itemDescription : "Maintain high data quality, improving the reliability of candidate information."
            },
        ],
    },
    {   
        menuName : "Improve Candidate Matching",
        menuIcon : ImproveCandidateMatchingIcon,
        content : [
            {
                itemName : "Improve Candidate Matching",
                itemDescription : "Utilize AI to better align candidates with job requirements, ensuring higher-quality hires and a more effective recruitment process."
            },
            {
                itemName : "Smart Matching",
                itemDescription : "AI & ML analyze resumes against job descriptions to find the best fit, improving match rates by 60%."
            },
            {
                itemName : "Enhanced Filters",
                itemDescription : "Refine candidate searches with advanced filters based on specific criteria."
            },
            {
                itemName : "Better Hires",
                itemDescription : "Boost your success rate in hiring and reduce turnover by finding candidates who are a perfect fit."
            },
        ],
    },
    {   
        menuName : "Save Time And Resources",
        menuIcon : SaveTimeAndResourcesIcon,
        content : [
            {
                itemName : "Save Time and Resources",
                itemDescription : "Automate repetitive tasks to free up your team for strategic decision-making, saving valuable time and resources."
            },
            {
                itemName : "Operational Efficiency",
                itemDescription : "Reduce manual workload, allowing your team to handle more strategic tasks."
            },
            {
                itemName : "Cost Savings",
                itemDescription : "Lower recruitment costs by cutting down on manual screening and administrative efforts."
            },
            {
                itemName : "Resource Optimization",
                itemDescription : "Allocate resources effectively to enhance overall productivity."
            },
        ],
    },
    {   
        menuName : "Seamless Integration",
        menuIcon : SeamlessIntegrationIcon,
        content : [
            {
                itemName : "Seamless Integration",
                itemDescription : "Integrate effortlessly with your existing systems for a smooth, uninterrupted workflow."
            },
            {
                itemName : "Compatibility",
                itemDescription : "Works with popular ATS and HR systems, ensuring a hassle-free setup."
            },
            {
                itemName : "Easy Implementation",
                itemDescription : "Quick integration process with minimal downtime."
            },
            {
                itemName : "Continuous Support",
                itemDescription : "Ongoing technical support ensures smooth operation and updates."
            },
        ],
    }
]

const HIWSection = ()=> {
    const isMobile = useResponsiveView();
    const [active, setActive] = useState(HIWContent[0].menuName);
    const { theme } = useTheme();
    const [swipableDivRef, { width:swipableDivWidth }] = useMeasure();
    
    return (
        <section className="hiw-section" id="hiw-section">
            <SectionTitle
                title={"Streamline Your Recruitment Workflow"}
            />

            <div className="hiw-box">
                {
                    isMobile ?
                    <motion.div className="left">
                        <motion.div className="inner-left" drag="x" dragConstraints={{right:15, left:-(5*swipableDivWidth + 6*14 - swipableDivWidth/2)}} whileTap={{cursor:"grabbing"}}>
                            {
                                HIWContent.map((menu, menuIndex)=>{
                                    return (
                                        <motion.div ref={swipableDivRef} key={menuIndex} className={`hiw-menu-name ${theme} ${active === menu.menuName ? "active" : null}`} onClick={()=>setActive(menu.menuName)}>
                                            <menu.menuIcon className="hiw-menu-name-icon" />
                                            <motion.div className="hiw-menu-name-text">{menu.menuName}</motion.div>
                                        </motion.div>
                                )})
                            }
                        </motion.div>
                    </motion.div>
                    :
                    <div className="left">
                        {
                            HIWContent.map((menu, menuIndex)=>{
                                return (
                                    <div key={menuIndex} className={`hiw-menu-name ${theme} ${active === menu.menuName ? "active" : null}`} onClick={()=>setActive(menu.menuName)}>
                                        <menu.menuIcon className="hiw-menu-name-icon" />
                                        <div className="hiw-menu-name-text">{menu.menuName}</div>
                                    </div>
                            )})
                        }
                    </div>
                }
                <div className="right">
                    {
                        HIWContent.map((menu, menuIndex)=>{
                            if(menu.menuName === active){
                                return menu.content.map((item, itemIndex)=>{
                                    return (
                                    <div key={itemIndex} className='hiw-item'>
                                        <h3>{item.itemName}</h3>
                                        <div>{item.itemDescription}</div>
                                        {itemIndex === (HIWContent[menuIndex].content.length-1) ? null : (
                                            <div className={`hiw-item-bar ${theme}`} />
                                        )}
                                    </div>
                                )})
                            }else{
                                return null;
                            }
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default HIWSection;