import { useTheme } from "../contexts/ThemeContext";
import SectionTitle from "../components/SectionTitle";
import "../styles/contact-us-section.css";
import FillButton from "../components/FillButton";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Collapse, TextField } from "@mui/material";
import { CustomCheckbox, CustomPhoneNumber, CustomSelect } from "../components/CustomInputs";
import { toast } from "react-toastify";
import { CustomAutoComplete, CustomTextField } from "../components/CustomMUIInputs";
import { color } from "framer-motion";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import eventBus from "../eventBus";
import { useCurrency } from "../contexts/CurrencyContext";

const ContactUsSection = ()=>{
    const { theme } = useTheme();
    const formRef = useRef();
    const [companySize, setCompanySize] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [interestedPlan, setInterestedPlan] = useState("");
    const { country } = useCurrency();

    const handleSubmit = (event)=>{
        event.preventDefault();
    
        const formData = new FormData(formRef.current);
        const formJson = Object.fromEntries(formData.entries());
        fetch("/contact-us", {
            headers: {
                'Content-Type' : "application/json"
            },
            method:"post",
            body:JSON.stringify({...formJson, phone_number:phoneNumber, interested_plan:interestedPlan, company_size:companySize})
        })
        .then(
            (response)=>{
                if(response.status === 200){
                    console.log(formJson);
                    toast.success("Form Submitted", {closeOnClick:true});
                    formRef.current.reset(); 
                    setCompanySize("");
                    setPhoneNumber("");
                    setInterestedPlan("");
                }else{
                    response.json().then(data=>{
                        console.log(data);
                    })
                }
            }
        )
        .catch(error=>{
            console.log(error);
        }) 
    }

    useEffect(()=>{
        const handlePlanSelected = (e)=>{
            console.log(e.detail)
            setInterestedPlan(e.detail || "");
        }

        eventBus.addEventListener('planSelected', handlePlanSelected);
        return ()=>eventBus.removeEventListener('planSelected', handlePlanSelected);
    }, [])

    return (
        <section className={`contact-us-section ${theme}`}>
            <SectionTitle title={"Transform Your Recruitment Today!"} />
            <div className="contact-us-desc" id="contact-us-section">
            Unlock the power of AI and Machine Learning to speed up your hiring process and discover top talent more efficiently.
            </div>
            <form className="contact-us-form" ref={formRef} onSubmit={handleSubmit}>
                <h2>Get started</h2>
                <div className="contact-us-form-section">
                    <div className="contact-us-form-subsection">
                        <CustomTextField
                            name="company_name"
                            id={"companyName"}
                            placeholder="Enter Company Name"
                            type="text"
                            label={"Company Name"}
                            required
                        />
                        <CustomAutoComplete
                            id="company_size"
                            name="company_size"
                            options={['Less than 10', '11-50', '51-100', '101-500', '501-1000', 'More than 1000']}
                            placeholder="Select Company Size"
                            label="Company Size"
                            disableClearable
                            freeSolo={false}
                            value={companySize}
                            onInputChange={(e, new_value)=>{
                                if(['Less than 10', '11-50', '51-100', '101-500', '501-1000', 'More than 1000'].includes(new_value)){
                                    setCompanySize(new_value);
                                }else{
                                    setCompanySize("");
                                }
                            }}
                            required
                        />

                        <CustomTextField
                            name="full_name"
                            id={"fullName"}
                            placeholder="Enter Full Name"
                            label={"Full Name"}
                            type="text"
                            required
                        />
                        <CustomTextField
                            name="email_id"
                            id={"emailID"}
                            placeholder="Enter Email ID"
                            label={"Email ID"} 
                            type="email"
                            required
                        />

                        <CustomPhoneNumber 
                            name={"phone_number"}
                            id={"phoneNumber"}
                            label={"Phone Number"}
                            placeholder="Enter Phone Number"
                            defaultCountry={country || null}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            required
                        />

                        <CustomAutoComplete
                            id="interested_plan"
                            name="interested_plan"
                            options={['Free', 'Essential', 'Premium', 'Enterprise']}
                            placeholder="Select Plan"
                            label="Interested Plan"
                            disableClearable
                            freeSolo={false}
                            value={interestedPlan}
                            onInputChange={(e, new_value)=>{
                                if(['Free', 'Essential', 'Premium', 'Enterprise'].includes(new_value)){
                                    setInterestedPlan(new_value);
                                }else{
                                    setInterestedPlan("");
                                }
                            }}
                            required
                        />
                    </div>
                </div>

                <div className="contact-us-form-section">
                    <div className="contact-us-form-subsection submit">
                        <FillButton className={`submit-button ${theme}`} inverse={true} value={"Submit"}/>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default ContactUsSection;